































import { defineComponent } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import SfNotification from '~/components/JetUi/molecules/SfNotification/SfNotification.vue';

export default defineComponent({
  name: 'NotificationBar',
  components: {
    SfNotification,
    SvgImage,
  },
  setup() {
    const { notifications } = useUiNotification();
    return {
      notifications,
    };
  },
});
